import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
// @mui
import { Container, Unstable_Grid2 as Grid } from '@mui/material';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import LoadingScreen from 'src/components/loading-screen';
// routes
import { paths } from 'src/routes/paths';
import {
  EcommerceProductDetailsInfo,
  EcommerceProductDetailsCarousel,
  EcommerceProductDetailsDescription,
  EcommerceProductRecommendation,
} from '../product/details';

import {
  EcommerceProductCarouselSkeleton,
  EcommerceProductDetailsSkeleton,
  EcommerceProductDescriptionSkeleton,
} from '../product/item';
import { ProductFilter } from '../../_travel/tour/filters';
// redux
import { useAuthContext } from '../../../auth/useAuthContext';
import { getChildProfileByParentID } from '../../../redux/slices/profile';
import { useDispatch, useSelector } from '../../../redux/store';
import { getAvailability } from '../../../redux/slices/product';
import { setChildData, setUserCheckInDate, setUserCheckOutDate } from '../../../redux/slices/general';
//----------------------------------------------------------------------

EcommerceProductView.propTypes = {
  product: PropTypes.object,
  isQuickview: PropTypes.string,
  handlePullData: PropTypes.func,
  enablesDayBefore: PropTypes.number,
};

export default function EcommerceProductView({ product, isQuickview, handlePullData, enablesDayBefore }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { childrenProfile, isLoading } = useSelector((data) => data.profile);
  const { childData } = useSelector((data) => data.general);
  const [productsData, setProduct] = useState([]);

  const [ProductimageData, setProductimage] = useState([]);

  const [ProductreviewData, setProductreview] = useState([]);

  const [ProductspecificationData, setProductspecification] = useState([]);

  const [ProductvariationData, setProductvariation] = useState([]);
  const [ProductcustomizationData, setProductcustomization] = useState([]);

  // useEffect(() => {
  //   dispatch(getChildProfileByParentID(user?.UserID))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (!isLoading) {
  //     if (childrenProfile.length > 0) {
  //       dispatch(setChildData(childrenProfile[0].ChildID));
  //     } else {
  //       navigate(paths.eCommerce.account.children);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [childrenProfile]);

  // useEffect(() => {
  //   if (user && user?.UserID) {
  //     if (user && childrenProfile) {
  //       if (!childrenProfile && childrenProfile.length > 0) {
  //         dispatch(setChildData(childrenProfile[0].ChildID))
  //       }
  //       if (childrenProfile.length === 0) {
  //         navigate(paths.eCommerce.account.children)
  //       }
  //     } else {
  //       dispatch(getChildProfileByParentID(user?.UserID))
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  useEffect(() => {
    if (childrenProfile.length === 0) {
      navigate(paths.eCommerce.account.children)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenProfile]);


  const recommendedProducts = product ? JSON.parse(product.ProductRecommendation) : [];

  const [ratingData, setRatingData] = useState([
    { value: '5start', number: 0, label: 5 },
    { value: '4start', number: 0, label: 4 },
    { value: '3start', number: 0, label: 3 },
    { value: '2start', number: 0, label: 2 },
    { value: '1start', number: 0, label: 1 },
    { value: '0start', number: 0, label: 0 },
  ]);

  useEffect(() => {
    if (product && product !== undefined) {
      setProduct(product);

      const specificationsDisplay = [];
      JSON.parse(product.ProductSpecification).map((spec) =>
        specificationsDisplay.push({
          label: spec.ProductSpecification,
          value: spec.ProductSpecificationValue,
        })
      );
      setProductimage(
        product.ProductImages !== null && product.ProductImages !== '[]'
          ? JSON.parse(product.ProductImages)
          : []
      );
      setProductreview(
        product.ProductReview !== null && product.ProductReview !== '[]'
          ? JSON.parse(product.ProductReview)
          : []
      );
      setProductvariation(
        product.ProductVariation !== null && product.ProductVariation !== '[]'
          ? JSON.parse(product.ProductVariation)
          : []
      );

      const customize = product.ProductCustomization !== null && product.ProductCustomization !== '[]'
        ? JSON.parse(product.ProductCustomization)
        : []

      if (customize.length > 0) {
        const removeDuplicate = customize.filter((ele, ind) => ind === customize.findIndex((elem) => elem.ProductVariationID === ele.ProductVariationID));

        const finalList = []
        removeDuplicate.map((data) =>
          finalList.push({
            ...data,
            detail: customize.filter((x) => x.ProductVariationID === data.ProductVariationID)
          })
        )
        setProductcustomization(finalList)
      } else {
        setProductcustomization([])
      }
      // setProductcustomization(
      //   product.ProductCustomization !== null && product.ProductCustomization !== '[]'
      //     ? JSON.parse(product.ProductCustomization)
      //     : []
      // );
      setProductspecification(specificationsDisplay);
    }
  }, [product]);

  useEffect(() => {
    if (ProductreviewData && ProductreviewData.length > 0) {
      const filterData = [...ratingData];

      ratingData.forEach((x, index) => {
        let reviewNum = 0;
        reviewNum = ProductreviewData.filter((y) => y.ProductReviewRating === x.label).length;

        filterData[index].number = reviewNum;
      });
      setRatingData(filterData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProductreviewData]);

  const navigateToShop = (checkIn, checkOut) => {
    dispatch(setUserCheckInDate(checkIn))
    navigate(paths.eCommerce.products("Category", 0))
    // dispatch(getAvailability(
    //   moment(checkIn).format('YYYY-MM-DD'),
    //   moment(checkIn).format('YYYY-MM-DD'),
    //   0))
  }



  return (
    <>
      {product ? (
        <Container sx={{ overflow: 'hidden' }}>
          <ProductFilter
            sx={{
              mt: { xs: 1, sm: 3, md: 5 },
              mb: { xs: 1, sm: 3, md: 5 },
            }}
            // pullProductData={handlePullData}
            selectedChildData={childData}
            ChildrenData={childrenProfile}
            pullProductData={navigateToShop}
          />

          {isQuickview !== 'true' && (
            <CustomBreadcrumbs
              links={[
                {
                  name: 'Home',
                  href: paths.eCommerce.products("Category", 0),
                },
                {
                  name: productsData?.ProductName,
                },
              ]}
              sx={{ my: { xs: 3, md: 5 } }}
            />
          )}

          <Grid container spacing={{ xs: 5, md: 8 }} pt={isQuickview === 'true' && 5}>
            <Grid xs={12} md={6} lg={7}>
              {ProductimageData && ProductimageData.length > 0 ? (
                <EcommerceProductDetailsCarousel images={ProductimageData} />
              ) : (
                <EcommerceProductCarouselSkeleton />
              )}
            </Grid>
            <Grid xs={12} md={6} lg={5}>
              {productsData && productsData.length !== 0 ? (
                <EcommerceProductDetailsInfo 
                  shopName={productsData.ShopName}
                  merchantID={productsData.MerchantID}
                  productId={productsData.ProductID}
                  promotion={
                    productsData.ProductPromotion !== '[]'
                      ? JSON.parse(productsData.ProductPromotion)
                      : []
                  }
                  variation={ProductvariationData}
                  customization={ProductcustomizationData}
                  name={productsData.ProductName}
                  price={productsData.ProductPrice}
                  rating={productsData.ProductRating}
                  review={productsData.ProductReviewCount}
                  priceSale={productsData.ProductPrice}
                  caption={productsData.ProductName}
                  selectedDate={productsData.SelectedDate}
                  product={product}
                  ProductimageData={ProductimageData}
                  isQuickview={isQuickview}
                  isAvailable={productsData.ISAVAILABLE}
                />
              ) : (
                <EcommerceProductDetailsSkeleton />
              )}
            </Grid>
          </Grid>

          <Grid container columnSpacing={{ md: 8 }}>
            <Grid xs={12} md={6} lg={7}>
              {productsData && productsData.ProductDescription ? (
                <EcommerceProductDetailsDescription
                  description={productsData.ProductDescription}
                  specifications={ProductspecificationData}
                />
              ) : (
                <EcommerceProductDescriptionSkeleton />
              )}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <LoadingScreen />
      )}

      {/* {isQuickview !== 'true' && (
        <ReviewEcommerce
          review={ProductreviewData}
          rating={product ? product.ProductRating : 0}
          ratingData={ratingData}
        />
      )} */}

      {recommendedProducts && recommendedProducts.length > 0 && isQuickview !== 'true' && (
        <EcommerceProductRecommendation productsData={recommendedProducts} />
      )}

      {/* {isQuickview !== 'true' && <Footer />} */}
    </>
  );
}
