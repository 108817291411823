import PropTypes from 'prop-types';
import moment from 'moment';
import { useState, useEffect } from 'react';
// utils
import { fCurrency } from 'src/utils/formatNumber';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// @mui
import {
  Box,
  Stack,
  Button,
  IconButton,
  Rating,
  Typography,
  TextField,
  Divider,
  Alert,
  Autocomplete,
} from '@mui/material';
// hooks
import Notification from 'src/components/Notification/Notification';
import useResponsive from 'src/hooks/useResponsive';
// routes
import { paths } from 'src/routes/paths';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import { QuantityControl } from 'src/components/hook-form';
import { checkAccessCategory, checkDateDiff } from 'src/utils/helpers';
//
import { ProductPricing, ProductCustomizeOptionPicker } from '../../components';
// auth
import { useAuthContext } from '../../../../auth/useAuthContext';
import {
  viewProductCart,
  addProductCart,
  updateProductCart,
  clearProductCartAction,
  viewProductWishlist,
  addProductWishlist,
  deleteProductWishlist,
  clearProductWishlistAction,
} from '../../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../../redux/store';

// ----------------------------------------------------------------------

EcommerceProductDetailsInfo.propTypes = {
  caption: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
  rating: PropTypes.number,
  review: PropTypes.number,
  variation: PropTypes.array,
  productId: PropTypes.number,
  promotion: PropTypes.array,
  product: PropTypes.object,
  ProductimageData: PropTypes.array,
  shopName: PropTypes.string,
  merchantID: PropTypes.number,
  isQuickview: PropTypes.string,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
  customization: PropTypes.array,
  isAvailable: PropTypes.number,
};

export default function EcommerceProductDetailsInfo({
  name,
  price,
  rating,
  review,
  caption,
  variation,
  productId,
  promotion,
  product,
  ProductimageData,
  shopName,
  merchantID,
  isQuickview,
  selectedDate,
  customization,
  isAvailable
}) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMdUp = useResponsive('up', 'md');
  const { productCart, productCartAction, productWishlist, productWishlistAction } = useSelector(
    (state) => state.product
  );
  const { userCheckInDate, childData } = useSelector((state) => state.general);
  const { childProfile } = useSelector((state) => state.user);

  const [selectedVariation, setVariation] = useState('');
  const [selectedCustomization, setCustomization] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(1);
  const [selectedVariationPrice, setSelectedVariationPrice] = useState({
    oriPrice: 0,
    promoPrice: 0,
    discount: 0,
    percent: false,
    isDiscount: false,
  });
  const [variationStock, setVariationStock] = useState('');
  const [isOverStock, setOverStock] = useState(false);
  const [isWishlistExist, setIsWishlistExist] = useState(false);
  const [wishlistAction, setWishlistAction] = useState('');
  const QuantitySelection = Array.from({ length: 100 }, (_, index) => index + 1);
  const [notification, setNotification] = useState({
    open: false,
    msg: '',
    type: 'success',
  });
  const overPreOrder = (userCheckInDate && product?.PreOrderDay > 0) ? checkDateDiff(userCheckInDate, new Date()) < product.PreOrderDay - 1 : false
  useEffect(() => {
    if (user && user.UserID) {
      dispatch(viewProductWishlist(user?.UserID));
      dispatch(viewProductCart(user?.UserID));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (productCartAction.length > 0) {
      dispatch(viewProductCart(user?.UserID));
      dispatch(clearProductCartAction());
      setNotification({
        open: true,
        msg: 'Updated Cart',
        type: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCartAction]);

  useEffect(() => {
    if (productWishlist.length > 0) {
      const filterData = productWishlist.filter(
        (x) => x.ProductID === productId && x.isHotel === 0
      );
      if (filterData.length > 0) setIsWishlistExist(true);
      else setIsWishlistExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productWishlist]);

  useEffect(() => {
    if (productWishlistAction.length > 0) {
      dispatch(viewProductWishlist(user?.UserID));
      dispatch(clearProductWishlistAction());

      if (wishlistAction === 'add')
        setNotification({ open: true, msg: 'Added to Wishlist', type: 'success' });
      else setNotification({ open: true, msg: 'Removed from Wishlist', type: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productWishlistAction]);

  useEffect(() => {
    if (variation.length > 0 && variation[0].ProductVariation === 'None') {
      handleVariationDetails(1,
        variation[0].ProductVariationDetailID,
        variation[0].ProductVariationPrice,
        variation[0].ProductStockAmount
      );
      setVariation(variation[0].ProductVariationDetailID);
      if (variation[0].ProductStockAmount >= selectedAmount) setOverStock(false);
      else setOverStock(true);
    }
    if (
      variation.length > 0) {
      const data = variation.filter((x) => x.ProductVariationValue.toLowerCase().includes(("No Drinks").toLowerCase()))
      if (data.length > 0) {
        handleVariationDetails(2,
          data[0].ProductVariationDetailID,
          data[0].ProductVariationPrice,
          data[0].ProductStockAmount
        );
        setVariation(data[0].ProductVariationDetailID);
        if (data[0].ProductStockAmount >= selectedAmount) setOverStock(false);
        else setOverStock(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variation]);


  useEffect(() => {
    
    if (selectedCustomization) {
      const filterData = variation.filter((x) => x.ProductVariationDetailID === selectedVariation);
      if (filterData.length > 0) {
        handleVariationDetails(3,
          selectedVariation,
          filterData[0].ProductVariationPrice,
          filterData[0].ProductStockAmount
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomization]);


  const handleVariationChange = (event) => {
    setVariation(Number(event.target.value));
    handleCheckVariationStock(Number(event.target.value), selectedAmount);
  };

  const handleCustomizationChange = (event, data) => {
    const details = data.detail.filter((x) => x.ProductCustomizationID === Number(event))
    const listing = selectedCustomization.filter((x) => !(x.ProductVariationID === data.ProductVariationID && x.ProductCustomizationID === Number(event))).filter((x) => x.ProductVariationID !== data.ProductVariationID)

    if (selectedCustomization.filter((x) => x.ProductVariationID === data.ProductVariationID && x.ProductCustomizationID === Number(event)).length > 0)
      setCustomization(listing)
    else
      setCustomization([...listing, {
        ...details[0]
      }]);


  }

  const handleAmountChange = (event, data) => {
    if (data !== '' && typeof data === 'number') {
      setSelectedAmount(Number(data));
      handleCheckVariationStock(selectedVariation, Number(data));
    }
  };

  const handleAddQuantity = (data) => {
    if (data !== '' && typeof data === 'number') {
      setSelectedAmount(Number(data) + 1);
      handleCheckVariationStock(selectedVariation, Number(data) + 1);
    }
  };

  const handleMinusQuantity = (data) => {
    if (data > 1) {
      setSelectedAmount(Number(data) - 1);
      handleCheckVariationStock(selectedVariation, Number(data) - 1);
    } else setNotification({ open: true, msg: 'Minimum 1 item is required', type: 'error' });
  };

  const handleCheckVariationStock = (data, quantity) => {
    if (data !== '') {
      const filterData = variation.filter((x) => x.ProductVariationDetailID === data);
      if (filterData.length > 0) {
        handleVariationDetails(4,
          data,
          filterData[0].ProductVariationPrice,
          filterData[0].ProductStockAmount
        );

        if (filterData[0].ProductStockAmount >= quantity) setOverStock(false);
        else setOverStock(true);
      } else
        setNotification({
          open: true,
          msg: 'There is an error while adding product quantity',
          type: 'error',
        });
    }
  };

  const handleAddToCart = () => {
    if (user && user.UserID && childData) {
      if (isOverStock === true && selectedVariation !== '')
        setNotification({ open: true, msg: 'Insufficient stock for your purchase', type: 'error' });
      else if (isOverStock === false && selectedVariation !== '') {
        const checkInDate = moment(userCheckInDate).format('YYYY-MM-DD');
        // const checkOutDate = moment(userCheckOutDate).format('YYYY-MM-DD');

        let selectedCust = ''
        const selectCustArr = []
        if (selectedCustomization.length > 0) {
          selectedCustomization.forEach((x, index) => {
            selectedCust += x.ProductCustomizationID
            selectCustArr.push(x.ProductCustomizationID)
            if (index < selectedCustomization.length - 1) {
              selectedCust += ';'
            }
          })
        }

        const productCartListing =
          productCart && productCart[0].ProductCart !== '[]'
            ? JSON.parse(productCart[0].ProductCart)
            : [];
        // const filterData = productCartListing.filter(
        //   (x) =>
        //     x.ProductVariationDetailID === selectedVariation &&
        //     x.ProductID === productId &&
        //     moment(x.CheckinDate).format('YYYY-MM-DD') === checkInDate &&
        //     moment(x.CheckoutDate).format('YYYY-MM-DD') === checkOutDate
        // );


        const filterData = productCartListing.filter(
          (x) =>
            x.ProductVariationDetailID === selectedVariation &&
            x.ProductID === productId &&
            x.RelatedTypeID === 2 && x.RelatedID === Number(childData) &&
            moment(x.CheckinDate).format('YYYY-MM-DD') === checkInDate
        ).map((x) => {
          const data = JSON.parse(x.CartCustomization).filter(item => selectCustArr.includes(item.CustomizationDetailsID))
          let returnData = '-'
          if (data.length === selectedCustomization.length)
            returnData = x

          return returnData
        })

        if (filterData.length > 0 && filterData[0] !== '-') {
          dispatch(
            updateProductCart(
              user.UserID,
              filterData[0].UserCartID,
              selectedAmount + Number(filterData[0].ProductQuantity),
              checkAccessCategory(0, 0)
            )
          );
        }
        else {
          dispatch(
            addProductCart(
              user.UserID,
              productId,
              selectedAmount,
              selectedVariation,
              0,
              checkInDate,
              checkInDate,
              checkAccessCategory(0, 0),
              2,
              childData,
              selectedCust === '' ? '-' : selectedCust
            )
          );

        }
      } else
        setNotification({ open: true, msg: 'Please select required variation', type: 'warning' });
    } else if (user && user.UserID) {
      navigate(paths.eCommerce.account.children)
    }
    else {
      navigate(paths.loginCover);
    }
  };

  const handleProceedToCart = () => {
    if (user && user.UserID && childData) {
      if (isOverStock === true && selectedVariation !== '')
        setNotification({ open: true, msg: 'Insufficient stock for your purchase', type: 'error' });
      else if (isOverStock === false && selectedVariation !== '' && variation.length > 0) {
        const processedPromoData = [];
        const filterList = [];

        const variationArr = variation.filter((x) => x.ProductVariationDetailID === selectedVariation)

        const oriPrice = variationArr[0].ProductVariationPrice;
        let promoPrice = variationArr[0].ProductVariationPrice;
        let discount = 'false';
        let purchaseLimit = variationArr[0].ProductStockAmount;
        let stockLimit = variationArr[0].ProductStockAmount;
        let promoID = 0;
        let promoTitle = '';
        const promotionData = product.ProductPromotion !== '[]' ? product.ProductPromotion : {};

        const selectedChild = childProfile.filter((x) => x.ChildID === Number(childData))[0]

        const selectCustArr = []
        selectedCustomization.forEach((x) => (
          selectCustArr.push({
            UserCartCustomizationID: 0,
            UserCartID: 0,
            ProductID: product.ProductID,
            CustomizationTypeID: x.ProductVariationID,
            CustomizationType: x.ProductVariation,
            CustomizationDetailsID: x.ProductCustomizationID,
            CustomizationDetails: x.ProductCustomization,
            CustomizationDetailsPrice: x.ProductCustomizationPrice,
            CustomizationQuantity: selectedAmount,
            CustomizationFinalPrice: x.ProductCustomizationPrice * selectedAmount,
          })
        ))

        if (promotion && promotion.length > 0) {
          const promoData = promotion[0];
          promoPrice = promoData.PromotionPrice;
          discount = promoData.ProductDiscount.toString();
          purchaseLimit = promoData.ProductPurchaselimit;
          stockLimit = promoData.ProductStockAvailable;
          promoID = promoData.PromotionID;
          promoTitle = promoData.PromotionTitle;
        }

        processedPromoData.push({
          ...product,
          ProductPromotion: promotionData,
          MerchantShopName: product.ShopName,
          UserCartID: 0,
          UserID: user?.UserID,
          isHotel: 0,
          isEvent: 0,

          CheckinDate: userCheckInDate,
          CheckinDate113: moment(userCheckInDate).format('DD MMM YYYY'),
          CheckoutDate: userCheckInDate,
          CheckoutDate113: moment(userCheckInDate).format('DD MMM YYYY'),

          ProductQuantity: selectedAmount,
          ProductVariationDetailID: variationArr[0].ProductVariationDetailID,
          ProductImage:
            ProductimageData && ProductimageData.length > 0
              ? ProductimageData[0].ProductMediaUrl
              : '',
          ProductVariationValue: variationArr[0].ProductVariationValue,
          ProductPrice: promoPrice,
          OriProductPrice: oriPrice,
          Discount: discount,
          ProductPurchaselimit: purchaseLimit,
          ProductStockLimit: stockLimit,
          PromotionID: promoID,
          PromotionTitle: promoTitle,
          Remarks: '',
          PromoCode: '',
          PromoCodeID: 0,
          PromoCodeDiscount: 0,
          ACCESSCATEGORYID: 1,
          CartCustomization: JSON.stringify(selectCustArr),
          RelatedID: childData,
          RelatedTypeID: 2,
          ChildName: selectedChild.ChildName,
          ChildProfileImage: selectedChild.ChildProfileImage,
          SchoolName: selectedChild.SchoolName,
          ClassName: selectedChild.ClassName,
          isAvailable: product.ISAVAILABLE
        });

        filterList.push({
          ...processedPromoData[0],
          cartList: processedPromoData,
        });

        const obj = {
          merchantLising: filterList,
          cartLising: processedPromoData,
          orderData: null,
          isFromBookLah: false,
          isFromEmporiaApp: false,
        };

        navigate(paths.eCommerce.cart, { state: obj });
      } else
        setNotification({ open: true, msg: 'Please select required variation', type: 'warning' });
    } else if (user && user.UserID) {
      navigate(paths.eCommerce.account.children)
    } else {
      navigate(paths.loginCover);
    }
  };

  const handleWishlist = () => {
    if (user && user.UserID) {
      if (isWishlistExist === true) {
        const filterData = productWishlist.filter(
          (x) => x.ProductID === productId && x.isHotel === 0
        );
        if (filterData.length > 0) {
          dispatch(deleteProductWishlist(filterData[0].UserWishlistID));
          setIsWishlistExist(false);
          setWishlistAction('delete');
        } else
          setNotification({
            open: true,
            msg: 'There is an error while delete wishlist',
            type: 'error',
          });
      } else {
        dispatch(addProductWishlist(user?.UserID, productId, 0, 0));
        setIsWishlistExist(true);
        setWishlistAction('add');
      }
    } else {
      navigate(paths.loginCover);
    }
  };


  const handleVariationDetails = (no,selectedId, selectedPrice, selectedStock) => {
    const customizationPrice = selectedCustomization.reduce((subtotal, item) => subtotal + item.ProductCustomizationPrice, 0);
    let obj = {
      oriPrice: selectedPrice + customizationPrice,
      promoPrice: selectedPrice + customizationPrice,
      discount: 0,
      percent: false,
      isDiscount: false,
    };

    let stock = selectedStock;
    if (selectedId !== '') {
      if (promotion.length > 0) {
        const filterData = promotion.filter(
          (x) => x.ProductVariationDetailID === selectedId && x.ProductID === productId
        );

        if (filterData.length > 0 && filterData[0].ProductStockAvailable > 0) {

          obj = {
            oriPrice: selectedPrice + customizationPrice,
            promoPrice: filterData[0].PromotionPrice + customizationPrice,
            discount: filterData[0].ProductDiscount,
            percent: true,
            isDiscount: true,
          };
          stock = filterData[0].ProductStockAvailable;
        }
      }
    }
    setSelectedVariationPrice(obj);
    setVariationStock(stock);
  };

  const actions = [
    {
      name: 'Facebook',
      icon: (
        <FacebookShareButton
          className="fadeIn"
          openShareDialogOnClick
          url={`https://be-delight.my/product/${productId}`}
          quote="Check this out at Bronson, Get it now!"
          hashtag="#Bronson"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      ),
    },

    {
      name: 'Telegram',
      icon: (
        <TelegramShareButton
          className="fadeIn"
          openShareDialogOnClick
          title={`Check this out at Bronson, Get it now!${name}`}
          url={`https://be-delight.my/product/${productId}`}
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      ),
    },
    {
      name: 'Twitter',
      icon: (
        <TwitterShareButton
          className="fadeIn"
          openShareDialogOnClick
          url={`https://be-delight.my/product/${productId}`}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      ),
    },
    {
      name: 'Whatsapp',
      icon: (
        <WhatsappShareButton
          className="fadeIn"
          openShareDialogOnClick
          title={`Check this out at Bronson, Get it now! ${name}`}
          separator=" Visit Now : "
          url={`https://be-delight.my/product/${productId}`}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      ),
    },
  ];

  const getCustomizationData = (data) => {
    let customize = ''
    const listing = selectedCustomization.filter((x) => x.ProductVariationID === data)
    if (listing.length > 0)
      customize = listing[0].ProductCustomizationID

    return customize
  }
  return (
    <>

      {selectedVariation !== '' && variationStock > 0 && (
        <Label color="success" sx={{ mb: 2 }}>
          In Stock ({variationStock})
        </Label>
      )}
      {selectedVariation !== '' && variationStock <= 0 && (
        <Label color="error" sx={{ mb: 2 }}>
          Out Of Stock
        </Label>
      )}

      {selectedVariation === '' && variation.filter((x) => x.ProductStockAmount > 0).length > 0 && (
        <Label color="success" sx={{ mb: 2 }}>
          In Stock
        </Label>
      )}
      {selectedVariation === '' &&
        variation.filter((x) => x.ProductStockAmount > 0).length === 0 && (
          <Label color="error" sx={{ mb: 2 }}>
            Out Of Stock
          </Label>
        )}

      {isAvailable === 0 &&
        <Label color="error" sx={{ mb: 2, ml: 1 }}>
          Not Available On {product.SelectedDate}
        </Label>
      }

      <Stack direction="column" spacing={{ xs: 1.5, md: 2 }}>
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="center" flexGrow={1}>
            <Iconify sx={{ cursor: "pointer" }}
              icon="ant-design:shop-filled"
              onClick={() => navigate(paths.eCommerce.merchantProduct(merchantID))}
            />
            <Typography sx={{ cursor: "pointer" }}
              variant="subtitle2"
              pl={1}
              onClick={() => navigate(paths.eCommerce.merchantProduct(merchantID))}
            >
              {' '}
              {shopName}{' '}
            </Typography>

            {isQuickview === 'true' && (
              <Typography
                variant="caption"
                pl={1}
                sx={{ textDecoration: 'underline', color: 'grey' }}
                onClick={() => navigate(paths.eCommerce.product(productId))}
              >
                {' '}
                View Product Details
              </Typography>
            )}
          </Stack>

          <Typography
            variant="h4"
            onClick={() => isQuickview === 'true' && navigate(paths.eCommerce.product(productId))}
          >
            {' '}
            {name}{' '}
          </Typography>
        </Stack>

        <Stack spacing={0.5} direction="row" alignItems="center">
          <Rating size="small" value={rating} readOnly precision={0.5} />

          <Typography variant="caption" sx={{ color: 'text.disabled' }}>
            ({review} reviews)
          </Typography>
        </Stack>

        {Number(selectedVariationPrice.oriPrice) ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <ProductPricing
              price={Number(selectedVariationPrice.promoPrice)}
              priceSale={Number(selectedVariationPrice.oriPrice)}
              discount={selectedVariationPrice.discount}
              isDiscount={selectedVariationPrice.isDiscount}
              percent={selectedVariationPrice.percent}
              sx={{ typography: 'h5' }}
            />
            <Typography variant="subtitle2">
              ({selectedDate}){' '}
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">
              {' '}
              {price === 0 ? fCurrency('0') : price} (
              ({selectedDate}){' '}
            </Typography>
          </Stack>
        )}

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {caption}
        </Typography>
      </Stack>

      <Stack spacing={3} sx={{ my: 3 }}>
        {variation.length > 0 && variation[0].ProductVariation !== 'None' && (
          <Stack spacing={{ xs: 0, md: 2 }}>
            <Typography variant="subtitle2">Variation</Typography>
            <ProductCustomizeOptionPicker
              value={selectedVariation}
              onChange={handleVariationChange}
              options={variation}
              optionLabel="ProductVariationValue"
              optionValue="ProductVariationDetailID"
              optionStock="ProductStockAmount"
            />
          </Stack>
        )}
      </Stack>

      {customization.length > 0 &&
        <Stack spacing={2} sx={{ my: 3 }}>
          {customization.length > 0 && customization.map((data, index) =>
          (
            <Stack spacing={{ xs: 0, md: 2 }}>
              <Typography variant="subtitle2">{data.ProductVariation}</Typography>
              <ProductCustomizeOptionPicker
                value={getCustomizationData(data.ProductVariationID)}
                onChange={(event) => handleCustomizationChange(event.target.value, data)}
                options={data.detail}
                optionLabel="ProductCustomization"
                optionValue="ProductCustomizationID"
                optionStock="ProductStockAmount"
              />
            </Stack>
          ))
          }
        </Stack>
      }


      {notification.open && notification.type !== 'success' && (
        <Stack spacing={3} sx={{ my: 5 }}>
          <Stack spacing={2}>
            <Alert severity={notification.type} sx={{ width: '100%' }}>
              {notification.msg}
            </Alert>
          </Stack>
        </Stack>
      )}

      {
        overPreOrder &&
        <Stack direction="row" alignItems="center" mb={2}>
          <Iconify icon="ph:warning-fill" width={14} sx={{ mr: 1, color: 'red' }} />
          <Typography variant='subtitle2' color="red">Preorder is required for this product</Typography>
        </Stack>
      }
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: "flex-start", md: "center" }}>
        <QuantityControl
          value={selectedAmount}
          onAdd={(e) => handleAddQuantity(e)}
          onMinus={(e) => handleMinusQuantity(e)}
          mt={-10}
          mb={10}
          width={{ xs: '100%', sm: '100%', md: '35%' }}
        />
        <Stack direction="row" spacing={1}>
          <Button
            onClick={handleAddToCart}
            fullWidth={!isMdUp}
            size="large"
            color="inherit"
            variant="contained"
            disabled={!variationStock > 0 || isAvailable === 0 || overPreOrder}
            startIcon={<Iconify icon="carbon:shopping-cart-plus" width={{ xs: 16, md: 18 }} />}
          >
            Add to Cart
          </Button>

          <Button
            onClick={handleProceedToCart}
            fullWidth={!isMdUp}
            size="large"
            color="primary"
            variant="contained"
            disabled={!variationStock > 0 || isAvailable === 0 || overPreOrder}
          >
            Buy Now
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed', my: 3 }} />

      <Stack spacing={3} direction="row">
        {/* <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}>
          <Button
            variant='none'
          >
            <Iconify icon="carbon:add-alt" sx={{ mr: 1 }} /> Compare
          </Button>
        </Stack> */}

        <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}>
          <Button variant="none" color="inherit" onClick={() => handleWishlist()}>
            <Iconify icon="carbon:favorite" sx={{ mr: 1, color: isWishlistExist && 'red' }} />{' '}
            Wishlist
          </Button>
        </Stack>

        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'fixed', bottom: { xs: 66, md: 16 }, right: 16 }}
          icon={<Iconify icon="carbon:share" />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={<Box pt={1}>{action.icon}</Box>}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
      </Stack>

      <Notification notification={notification} setNotification={setNotification} />
    </>
  );
}
