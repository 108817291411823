import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Alert, Box, Button, Container, Divider, Unstable_Grid2 as Grid, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';
//
import { paths } from 'src/routes/paths';
import { ServerConfiguration } from '../../../utils/serverConfig';
import { navConfig, pageLinks } from '../nav/config-navigation';
// ----------------------------------------------------------------------

const StyledAppStoreButton = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  padding: '5px 12px',
  margin: theme.spacing(1),
  color: theme.palette.common.white,
  border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
  background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
  '& .MuiButton-startIcon': {
    marginLeft: 0,
  },
}));

// ----------------------------------------------------------------------

export default function Footer() {
  const isMdUp = useResponsive('up', 'md');
  const isSmDown = useResponsive('down', 'md');

  const url = ServerConfiguration.ServerUrl;
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [isvalid, setIsValid] = useState(false);

  const { pathname } = useLocation();

  const mobileList = navConfig.find((i) => i.title === 'Pages')?.children || [];

  const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

  const renderLists = isMdUp ? desktopList : mobileList;

  const isHome = pathname === '/';

  const footerLink = [
    { to: paths.support, title: "FAQs" },
    { to: paths.term, title: " Terms and condition" },
    { to: paths.rule, title: "Privacy Policy" },
    { to: paths.contactus, title: "Contact Us" },
  ]


  useEffect(() => {
    if (email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValid(regex.test(email))
    }
  }, [email]);

  const handleSubscription = async () => {
    try {
      const subject = 'Subscribe to Bronson Weekly Newsletter!'
      const message = `I&apos;m reaching out to express my interest in subscribing to Bronson's weekly newsletter. I&apos;ve heard wonderful things about your serene retreat and would love to stay informed about the latest articles, updates, and offers.`
      const response = await fetch(
        `${url}User_AddUserFeedback?SUBJECT=${subject}&BODY=${message}&NAME=${email}&EMAIL=${email}`
      );
      const json = await response.json();
      const resp = JSON.parse(json)[0];
      if (resp && resp.ReturnVal === 1) {
        enqueueSnackbar('Thank you for your subscription', { variant: 'success' });
        setEmail("")
        setIsValid(false)
      } else {
        enqueueSnackbar(
          'There is an error to sent your subscription. Kindly contact administrator for assistant',
          { variant: 'error' }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (data) => {
    setEmail(data.target.value)
  }

  const simpleFooter = (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Logo single />

      <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
        © 2024. All rights reserved
      </Typography>
    </Container>
  );


  const mainFooter = (
    <>
      <Divider sx={{ mt: 5 }} />

      <Container
        sx={{
          overflow: 'hidden',
          py: { xs: 8, md: 10 },
        }}
      >
        <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
          <Grid xs={12} md={5.5}>
            <Stack spacing={{ xs: 3, md: 5 }}>
              <Stack alignItems="flex-start" spacing={3}>
                <Logo />
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
                  Bronson and CheaLing Enterprises is your one-stop solution for both nourishment and education.
                  As a trusted food provider in canteens, we serve wholesome meals to keep hunger at bay.
                  Additionally, our simple stationary shop within primary schools offers essential supplies like exercise books, pens, and tools,
                  ensuring students have everything they need for successful learning.
                  Discover convenience and quality with us!
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Typography variant="h6">Let’s stay in touch</Typography>
                <Typography variant={isSmDown ? "subtitle2" : "caption"} sx={{ color: 'text.secondary' }}>
                  Subscribe to our newsletter to receive latest articles to your inbox weekly.
                </Typography>
              </Stack>
              {
                !isvalid && email !== '' && <Alert severity="error" sx={{ width: '100%' }}> Valid Email is Required </Alert>
              }
              <TextField
                fullWidth
                hiddenLabel
                placeholder="Email address"
                value={email}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button variant="contained" color="inherit" size="large" disabled={!isvalid} onClick={() => handleSubscription()}>
                        Subscribe
                      </Button>
                    </InputAdornment>
                  ),
                  sx: { pr: 0.5 },
                }}
              />
              {/* <Stack spacing={2}>
                <Typography variant="h6">Social</Typography>
                <Stack direction="row" alignItems="center">
                  {_socials.map((social) => (
                    <IconButton key={social.value} color="primary" onClick={() => window.open(social.link)}>
                      <Iconify icon={social.icon} />
                    </IconButton>
                  ))}
                </Stack>
              </Stack> */}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Stack
          spacing={2}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{ py: 3, textAlign: 'center' }}
        >
          <Typography variant={isSmDown ? "subtitle2" : "caption"} sx={{ color: 'text.secondary' }}>
            © 2024. All rights reserved
          </Typography>

          <Box
            gap={{ xs: 1, md: 1, }}
            display="grid"
            sx={{ py: 3 }}
            gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 2fr)', md: 'repeat(4, 1fr)', lg: 'repeat(4, 1fr)' }}
          >
            {
              footerLink.map((data) =>
                <Link
                  component={RouterLink}
                  variant="caption"
                  to={data.to}
                  sx={{ color: 'text.secondary' }}
                  key={`footer_${data.title}`}
                >
                  {data.title}
                </Link>
              )
            }
          </Box>

        </Stack>
      </Container>
    </>
  );

  return <footer>{isHome ? mainFooter : mainFooter}</footer>;
}

// ----------------------------------------------------------------------

function AppStoreButton({ ...other }) {
  return (
    <Stack direction="row" flexWrap="wrap" {...other}>
      <StyledAppStoreButton startIcon={<Iconify icon="ri:apple-fill" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download on the
          </Typography>

          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Apple Store
          </Typography>
        </Stack>
      </StyledAppStoreButton>

      <StyledAppStoreButton startIcon={<Iconify icon="logos:google-play-icon" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download from
          </Typography>
          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Google Play
          </Typography>
        </Stack>
      </StyledAppStoreButton>
    </Stack>
  );
}
